<template>
  <div class="home">
    <!-- <div class="sweiper-box">
      <My-Swiper
        v-if="swiperArr.length > 0"
        :swiperConfig="swiperConfig"
        :list="swiperArr"
        :current="swiperCurrent"
        @SwiperFun="SwiperFun"
      ></My-Swiper>
    </div> -->
    <div class="msg-box">
      <!-- <div class="wrapper"> -->
        <el-carousel height="36px" direction="vertical" indicator-position="none">
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <div class="title" @click="newsItem(item)">
              <img style="margin-right: 10px; margin-top: -1px;" src="../assets/image/xiaoxi.png" alt="">
              <span style="vertical-align: middle">{{item.title}}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      <!-- </div> -->
    </div>
    <div class="cont-box wrapper">
      <div class="clearfix" style="margin: 10px 0 15px;">
        <My-Swiper
          class="pull-left"
          v-if="swiperArr.length > 0"
          :swiperConfig="swiperConfig"
          :list="swiperArr"
          :current="swiperCurrent"
          @SwiperFun="SwiperFun"
        ></My-Swiper>
        <div class="pull-right" style="width: 488px;height: 320px;background: linear-gradient(180deg, #F7F9FD 0%, #FFFFFF 100%);">
            <ul style="padding: 0 10px;">
              <li :class="{'news-li-border':index > 0}" v-for="(item, index) of newsListOne" :key="index" @click="newsItem(item)">
                <div v-if="index == 0" style="padding: 20px 10px 10px 10px;">
                  <!-- <div class="news-one-image">
                    <My-LoadImage height="93" width="130" :url="$imgBaseUrl + item.image" type="cover"></My-LoadImage>
                  </div> -->
                  <div class="news-one-cont" style="height: auto;">
                    <div class="news-one-cont-title black-title">{{item.title}}</div>
                    <div class="news-one-cont-text" style="margin-top: 10px;padding-left: 12px;">{{item.introtext}}</div>
                  </div>
                </div>
                <!-- <div v-else class="news-li"> -->
                <div v-if="index != 0 && index < 6" class="news-li">

                  <div class="news-left" style="width: 320px;">{{item.title}}</div>
                  <div class="news-time">{{item.publishTime}}</div>
                </div>
              </li>
            </ul>
        </div>
      </div>
      <!-- <div class="banner-box">
        <img :src="bannerImg" alt="">
      </div> -->
      <div class="cont-item">
        <div class="cont-item-title">产品与服务</div>
        <div class="cont-item-text"></div>
        <div class="cont-item-nav-box">
          <ul class="clearfix">
            <li v-for="(item, index) of serviceList" :key="index" class="pull-left" :style="`width: ${100 / serviceList.length}%`">
              <div class="nav-a-item cursor" @click="toDetails(item.url)">
                <div class="nav-a-item-icon-box">
                  <img :src="item.icon" alt="">
                </div>
                <p>{{item.name}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="banner-box">
        <img :src="bannerImg" alt="">
      </div>
      <div class="cont-item">
        <div class="cont-item-title">解决方案</div>
        <div class="cont-item-text">围绕咨询设计、软件服务、智能化建设、工程实施、运营服务以及资源整合等六个方面，规划核心业务板块，为客户提供多种解决方案</div>
        <div class="cont-item-nav-box" style="padding: 30px 25px;">
          <ul class="clearfix">
            <li v-for="(item, index) of schemeList" :key="index" class="pull-left" :style="`width: ${100 / schemeList.length}%`">
              <div class="nav-b-item cursor" @click="toNews(item.url)">
                <div class="nav-b-item-icon-box">
                  <img :src="item.icon" alt="">
                </div>
                <p>{{item.name}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="fourth-floor clearfix">
        <div class="left-news">
          <My-Title title="行业动态" :src="`/news?id=${hydt.id}&content=${hydt.name}`"></My-Title>
          <div style="padding-left: 15px;">
            <ul>
              <li :class="{'news-li-border':index > 0}" v-for="(item, index) of newsListOne" :key="index" @click="newsItem(item)">
                <div v-if="index == 0" class="news-one">
                  <div class="news-one-image">
                    <My-LoadImage height="93" width="130" :url="$imgBaseUrl + item.image" type="cover"></My-LoadImage>
                  </div>
                  <div class="news-one-cont">
                    <div class="news-one-cont-title">{{item.title}}</div>
                    <div class="news-one-cont-text">{{item.introtext}}</div>
                    <div class="news-one-cont-time">{{item.publishTime}}</div>
                  </div>
                </div>
                <div v-else class="news-li">
                  <div class="news-left" style="width: 400px;">{{item.title}}</div>
                  <div class="news-time">{{item.publishTime}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-news">
          <!-- <div class="content-box" style="padding: 0;"> -->
            <My-Title title="合作交流" :src="`/news?id=${hzjl.id}&content=${hzjl.name}`"></My-Title>
            <div style="padding: 0 15px;">
              <div class="banner-box" style="height: 103px;margin: 10px 0;">
                <img :src="bannerImgB" alt="">
              </div>
              <ul>
                <li class="news-li news-li-border" v-for="(item, index) of newsListTwo" :key="index" @click="newsItem(item)">
                  <div class="news-left" style="width: 180px;">{{item.title}}</div>
                  <div class="news-time">{{item.publishTime}}</div>
                </li>
              </ul>
            </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="cont-item">
        <My-Title title="友情链接" boeder="no"></My-Title>
        <div class="cont-item-nav-box" style="padding: 30px 25px;margin-top: 10px;">
          <div class="clearfix">
            <div class="riendship-item" v-for="(item, index) of riendshipUrlList" :key="index" @click="riendshipItem(item.url)">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyTitle from '@/components/title/MyTitle'
import MySwiper from '@/components/swiper/Swiper'
import MyLoadImage from '@/components/loadImage/LoadImage'

import { GetHeaderNavColumnNewsList, HomeImages, GetHeaderNavList } from '@/api/http'

export default {
  name: 'Home',
  data () {
    return {
      hydt: {},
      hzjl: {},
      xwdt: {},
      bannerImg: require('../assets/image/banner.jpg'),
      bannerImgB: require('../assets/image/banner_b.jpg'),
      serviceList: [
        { name: '货运平台', icon: require('../assets/image/c_1.png'), url: 'a5be7e8eb7a4b3fa82395d35e57a6097' },
        { name: 'TMS', icon: require('../assets/image/c_2.png'), url: '7b2b8360b2ac1e4869b6bcb9b908145f' },
        { name: '运输方案', icon: require('../assets/image/c_6.png'), url: '04b45cc41b8f8557795fe6d7144c23cb' },
        { name: '路径规划', icon: require('../assets/image/c_3.png'), url: '034d017b1530f6a3724636a58743bda3' },
        // { name: '大件专项', icon: require('../assets/image/c_4.png'), url: '' },
        { name: '培训服务', icon: require('../assets/image/c_5.png'), url: 'efb2cc0d9b975019d7c12a01f6400a4d' }
      ],
      schemeList: [
        // { name: '港口', icon: require('../assets/image/f_1.png'), url: '' },
        { name: '交通信息化', icon: require('../assets/image/f_2.png'), url: '/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=a650d72583a7b7bc2654b2cdb498af27' },
        { name: '城市信息化', icon: require('../assets/image/f_3.png'), url: '/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=fdf28573bf6573ed8611abdf62ed3035' },
        { name: '物流信息化', icon: require('../assets/image/f_4.png'), url: '/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=689c9521920f2d85578c87f3a7d0cdb1' },
        { name: '楼宇信息化', icon: require('../assets/image/f_8.png'), url: '/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=59f19d4de236c06f9e18e73b09d800a6' }
        // { name: '贸易便利化', icon: require('../assets/image/f_6.png'), url: '' },
        // { name: '绿色货运', icon: require('../assets/image/f_7.png'), url: '' }
      ],
      carouselList: [],
      swiperArr: [],
      swiperConfig: {
        type: 'image',
        autoplay: true, // 自动播放 默认false
        interval: 4000, // 自动播放的间隔 默认3000
        circular: true, // 首位衔接 默认false
        indicator: true, // 指示点 默认false
        height: 320,
        width: 480
      },
      swiperCurrent: 0,
      newsListOne: '',
      newsListTwo: '',
      riendshipUrlList: [
        { name: '中国交通运输部', url: 'https://www.mot.gov.cn' },
        { name: '交通运输部规划研究院', url: 'http://www.tpri.org.cn' },
        { name: '交通运输部水利研究院', url: 'https://www.wti.ac.cn/wti' },
        { name: '交通运输部公路研究院', url: 'https://www.rioh.cn' },
        { name: '交通运输部科学研究院', url: 'http://www.motcats.com.cn' },
        { name: '交通运输部天津水运工程科学研究院', url: 'https://www.tiwte.ac.cn/index.aspx' },
        { name: '交通运输部海事局', url: 'https://www.msa.gov.cn' },
        { name: '交通运输部长江航务管理局', url: 'https://cjhy.mot.gov.cn' },
        { name: '交通运输部珠江航务管理局', url: 'https://zjhy.mot.gov.cn' },
        { name: '中国船级社', url: 'https://www.ccs.org.cn/ccswz' },
        { name: '中国交通通信信息中心', url: 'https://www.cttic.cn' },
        { name: '交通运输部管理干部学院', url: 'https://www.motmti.cn' },
        { name: '中国交通运输协会', url: 'http://www.cctaw.cn' },
        { name: '中国交通运输协会信息专业委员会', url: '' }
      ]
    }
  },
  components: {
    MyTitle,
    MySwiper,
    MyLoadImage
  },
  created () {
    this.getimage()
    this.GetHeaderNavList()
  },
  mounted () {
    // this.swiperConfig.width = document.body.clientWidth || document.documentElement.clientWidth
  },
  methods: {
    toDetails (id) {
      const { href } = this.$router.resolve({
        path: '/news/details',
        query: { id: id }
      })
      window.open(href, '_blank')
    },
    toNews (url) {
      if (url) {
        this.$router.push({ path: url })
      }
    },
    riendshipItem (url) {
      if (url) {
        window.open(url)
      }
    },
    newsItem(item) {
      if (item.url) {
        window.open(item.url)
      } else {
        const { href } = this.$router.resolve({
          path: '/news/details',
          query: { id: item.id }
        })
        window.open(href, '_blank')
      }
    },
    SwiperFun (e) {
      const url = this.imageList[e].url
      if (url) {
        window.open(url)
      }
    },
    // 获取轮播图
    getimage () {
      HomeImages({}).then((res) => {
        if (res.code === '000') {
          const array = res.result
          const arr = []
          array.forEach((val, ind) => {
            arr.push({ url: val.url, image: this.$imgBaseUrl + val.image })
          })
          this.swiperArr = arr
          this.imageList = array
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取新闻列表
    getNewsList (pageSize, val, flag) {
      GetHeaderNavColumnNewsList({ cmsType: val, pageSize }).then((res) => {
        if (res.code === '000') {
          const array = res.result.records
          if (flag === 1) {
            this.newsListOne = array
          } else if (flag === 2) {
            this.newsListTwo = array
          } else if (flag === 3) {
            this.carouselList = array
          }
        } else {
          this.$message({ message: res.msg, type: 'error' })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 获取一级菜单
    GetHeaderNavList() {
      GetHeaderNavList().then(res => {
        if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
        for (let i = 0; i < res.result.length; i++) {
          if (res.result[i].code == 'hydt') {
            this.hydt = res.result[i]
          }
          if (res.result[i].code == 'hzjl') {
            this.hzjl = res.result[i]
          }
          if (res.result[i].code == 'xwdt') {
            this.xwdt = res.result[i]
          }
        }
        this.getNewsList(8, this.hydt.id, 1)
        this.getNewsList(7, this.hzjl.id, 2)
        this.getNewsList(5, this.xwdt.id, 3)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.black-title{
  padding: 0 0 10px 15px;
  position: relative;
  border-bottom: 1px solid #E9E9E9;
}
.black-title::before{
  content: '';
  height: 16px;
  width: 4px;
  background-color: #155BDD;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  border-radius: 2px;
  left: 0;
}
.sweiper-box{
  height: 287px;
}
.msg-box{
  width: 980px;
  margin: 10px auto 0;
  padding: 0 100px 0 15px;
  height: 36px;
  background-color: #F0F0F0;
  color: #666;
  font-size: 14px;
  .title {
    cursor: pointer;
    line-height: 36px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cont-box{
  padding-bottom: 5px;
}
.cont-item{
  padding-top: 25px;
}
.cont-item-title{
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
.cont-item-text{
  width: 600px;
  margin: auto;
  text-align: center;
  padding: 15px 0 25px 0;
  color: #9A9A9A;
}
.cont-item-nav-box{
  padding: 45px 0 60px 0;
  background: linear-gradient(180deg, #F7F9FD 0%, #FFFFFF 100%)
}
.nav-a-item{
  font-size: 16px;
  text-align: center;
  width: 100px;
  margin: auto;
}
.nav-a-item-icon-box{
  height: 100px;
  padding-top: 26px;
  background-color: rgba(21, 91, 221, .05);
  border-radius: 40%;
  margin-bottom: 30px;
}
.nav-a-item:hover .nav-a-item-icon-box{
  background-color: rgba(21, 91, 221, .15);
}
.nav-a-item-icon-box img{
  display: block;
  height: 48px;
  width: 48px;
  margin: auto;
}
.banner-box{
  height: 85px;
  background: rgba(216, 216, 216, 0.2);
}
.nav-b-item{
  width: 80px;
  margin: auto;
  text-align: center;
}
.nav-b-item-icon-box{
  height: 50px;
  width: 50px;
  padding-top: 13px;
  margin: auto;
  background-color: rgba(21, 91, 221, .8);
  border-radius: 40%;
  margin-bottom: 20px;
}
.nav-b-item:hover .nav-b-item-icon-box{
  background-color: rgba(21, 91, 221, .6);
}
.nav-b-item-icon-box img{
  display: block;
  height: 24px;
  width: 24px;
  margin: auto;
}
.fourth-floor {
  margin-top: 35px;
  padding-bottom: 10px;
  .left-news {
    float: left;
    width: 600px;
  }
  .right-news {
    float: right;
    width: 350px;
  }
}
.news-one{
  position: relative;
  padding: 20px 10px 10px 145px;
}
.news-one-image{
  height: 93px;
  width: 130px;
  border-radius: 3px;
  position: absolute;
  top: 20px;
  left: 0;
}
.news-one-cont{
  height: 93px;
  position: relative;
  padding-top: 2px;
}
.news-one-cont-title{
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.news-one-cont-title:hover{
  color: #155BDD;
}
.news-one-cont-text{
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  height: 36px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 6px;
}
.news-one-cont-time{
  position: absolute;
  left: 0;
  bottom: 5px;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
}
.news-li{
  position: relative;
  padding: 12px 0px 12px 22px;
  line-height: 16px;
}
.news-li-border{
  border-bottom: 1px dashed  rgba(233, 233, 233, 1)
}
.news-li::before{
  content: '';
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #D8D8D8;
  position: absolute;
  top: 50%;
  left: 7px;
  margin-top: -3px;
}
.news-left{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.news-left:hover{
  color: #155BDD;
}
.news-time{
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 10px;
}
.riendship-item{
  float: left;
  width: 25%;
  padding-bottom: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.riendship-item:hover{
  color: #155BDD;
  opacity: 0.8;
}
</style>
