<style scoped>
.title-bt{
    height: 50px;
    padding-left: 15px;
    line-height: 50px;
    position: relative;
    font-size: 24px;
}
.title-bt-border{
  border-bottom: 1px solid rgba(240, 240, 240, 1)
}
.title-bt::before{
    content: '';
    height: 24px;
    width: 5px;
    background-color: #155BDD;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
}
.more{
  height: 20px;
  line-height: 20px;
  padding: 0 30px 0 12px;
  position: absolute;
  font-size: 14px;
  top: 15px;
  right: 0px;
}
.quan{
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #155BDD;
  position: absolute;
  top: 2px;
  right: 12px;
}
.quan::after{
  content: '';
  height: 3px;
  width: 3px;
  border-top: 2px solid #155BDD;
  border-right: 2px solid #155BDD;
  transform: rotate(45deg);
  position: absolute;
  top: 2.5px;
  left: 2px;
}
</style>

<template>
    <div class="title-bt" :class="{'title-bt-border': boeder != 'no'}">
      <span style="font-weight: bold;">{{title}}</span>
      <router-link v-if="src" class="more" :to="{path:src, query:{}}">
        <span>更多</span>
        <span class="quan"></span>
      </router-link>
    </div>
</template>

<script>
export default {
  name: 'MyTitle',
  data () {
    return {}
  },
  props: ['title', 'src', 'boeder'],
  components: {},
  watch: {},
  created () {},
  methods: {

  }
}
</script>
