<style scoped>
.load-box{
  position: relative;
  /* border: 1px solid #E9E9E9; */
  z-index: 0;
  cursor: pointer;
}
.load-box-border{
  border-radius: 5px;
  /* border: 1px solid #C2C2C2; */
}
.load-text{
  width: 100%;
  text-align: center;
  background-color: #f8f8f8;
  color: #dcdbdb;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.5s linear;
}
.image{
  opacity: 0;
  z-index: 10;
  transition: all 0.5s linear;
  height: 100%;
  width: 100%
}
.imageon{
  opacity: 1;
}
.imageof{
  opacity: 0;
}
.aa{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  background-color: aquamarine;
}
</style>

<template>
  <div class="load-box" :class="{'load-box-border': border !== undefined && border !== false}" :style="`height: ${height}px;width: ${width}px;`" @click="previewImage">
    <img @load="lazy" :src="url" alt="" class="image" :class="{'imageon': load}" :style="`object-fit: ${type};`">
    <div class="load-text" :style="`line-height: ${height}px;`" :class="{'imageof':load}">路航通</div>
  </div>
</template>

<script>
export default {
  name: 'LoadImage',
  data () {
    return {
      load: false
    }
  },
  props: {
    border: {
      default: ''
    },
    height: {
      default: ''
    }, // 高 必穿
    width: {
      default: ''
    }, // 宽 必穿
    url: {
      default: ''
    }, // 图片地址 必穿
    type: { // 图片显示形式
      default: 'fill'
    }
  },
  // type: fill  contain    cover    none
  components: {},
  watch: {},
  created () {},
  methods: {
    lazy (e) {
      this.load = true
    },
    previewImage () {
      this.$modal('img', this.$props.url)
    }
  }
}
</script>
